const setCookie = (name: string, value: string, url: string, days: number) => {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + date.toUTCString()
  const path = 'path=' + url
  document.cookie = `${name}=${value};${expires};${path}`
}

const checkCookieExists = (cookieName: string, expectedValue?: string): boolean => {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    const [name, value] = cookie.split('=')
    if (expectedValue) {
      if (name === cookieName && value === expectedValue) {
        return true
      }
    } else {
      if (cookie.startsWith(cookieName + '=')) {
        return true
      }
    }
  }
  return false
}

export const MenuBadge = () => {
  // show badge "New" for statistics menu item

  if (!window.location.href.includes('statistics') && !checkCookieExists('show_csat_badge')) {
    setCookie('show_csat_badge', 'true', '/', 365)
  } else if (window.location.href.includes('statistics') && checkCookieExists('show_csat_badge')) {
    setCookie('show_csat_badge', 'false', '/', 365)
  }

  const menuItems = document.querySelectorAll('.dashboard-nav__link-item')

  menuItems.forEach((element) => {
    const badge = element.querySelector('.dashboard-nav__link-sub-item-badge')
    checkCookieExists('show_csat_badge', 'true')
      ? badge?.classList.remove('hidden')
      : badge?.classList.add('hidden')
  })
}

MenuBadge()
